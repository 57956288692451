import React from 'react'
import { connect } from 'react-redux'

import { isEmpty } from 'lodash'
import { useHistory } from 'react-router-dom'
import SearchModulesForm from '../components/form/SearchModulesForm'
import NewCreateModuleForm from '../components/form/NewCreateModuleForm'
import { getModuleById } from '../store/modules/actions'
import { CircularProgress } from '@material-ui/core'

const NewModulesPage = ({
  componentAttribute,
  match,
  currentModule,
  isFetching,
  userOccupations,
  getModuleById,
  moduleIsFetching
}) => {
  const moduleId = match.params.moduleId
  const history = useHistory()

  React.useEffect(() => {
    if (moduleId) {
      getModuleById({ ids: moduleId })
    }
  }, [])

  if (componentAttribute?.isEdit && isEmpty(currentModule)) {
    return <CircularProgress />
  } else {
    if (componentAttribute?.isCreate) {
      return <NewCreateModuleForm history={history} />
    } else if (componentAttribute?.isEdit) {
      return (
        <NewCreateModuleForm
          isEdit
          history={history}
          initialValues={{
            id: currentModule?.id,
            ...currentModule?.attributes
          }}
        />
      )
    }
    return <SearchModulesForm />
  }
}

const mapStateToProps = state => {
  return {
    currentSchool: state.school.currentSchool.school,
    currentModule: state.modules.currentItem.item,
    moduleIsFetching: state.modules.currentItem.isFetching
  }
}

const mapDispatchToProps = dispatch => ({
  getModuleById: data => dispatch(getModuleById(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(NewModulesPage)
