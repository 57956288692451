import React from 'react'
import MaterialButton from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
    borderRadius: 8,
    boxShadow: 'none',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontSize: '16px',
    [theme.breakpoints.down('426')]: {
      fontSize: '14px'
    }
  },
  whiteFont: {
    color: '#FFF'
  },
  root: {
    '& .MuiButton-outlined:hover': {
      backgroundColor: 'unset'
    }
  }
}))

function Button ({
  variant = 'contained',
  color = 'primary',
  startIcon,
  children,
  onclick,
  disabled,
  endIcon,
  ...props
}) {
  const classes = useStyles()
  return (
    <div className={`${classes.root} ${props.rootClass}`}>
      <MaterialButton
        variant={variant}
        disabled={disabled}
        color={color}
        className={`${classes.button} ${color === 'secondary' &&
          variant === 'contained' &&
          classes.whiteFont}`}
        startIcon={startIcon}
        endIcon={endIcon}
        onClick={onclick}
        {...props}
      >
        {children}
      </MaterialButton>
    </div>
  )
}

export default Button
