export default [
  {
    name: 'theme_module',
    attributes: {
      title: '',
      description: '',
      themes_order: [],
      grade_id: 0,
      cover_id: 0,
      school_id: '',
      created_at: '',
      color: '',
      status: '',
      grade: {
        jsonApi: 'hasOne',
        type: 'grades'
      },
      cover: {
        jsonApi: 'hasOne',
        type: 'covers'
      }
    },
    options: {
      collectionPath: 'theme_modules',
      type: 'theme_modules'
    }
  },
  {
    name: 'grade',
    id: 0,
    attributes: {
      name: '',
      school_id: 0,
      segment_id: 0,
      description: '',
      split_desc: '',
      school_ids: [],
      school: {
        jsonApi: 'hasOne',
        type: 'schools'
      },
      segment: {
        jsonApi: 'hasOne',
        type: 'segments'
      },
      classrooms: {
        jsonApi: 'hasMany',
        type: 'classroom'
      },
      schedule_templates: {
        jsonApi: 'hasMany',
        type: 'schedule_template'
      },
      theme_schedules: {
        jsonApi: 'hasMany',
        type: 'theme_schedule'
      }
    },
    options: {
      collectionPath: 'grades',
      type: 'grades'
    }
  },
  {
    name: 'timesheet',
    id: 0,
    attributes: {
      user_id: 0,
      school_id: 0,
      shift: 0,
      working_days: [],
      created_at: '',
      updated_at: '',
      entrance_hour: '',
      exit_hour: '',
      user: {
        jsonApi: 'hasOne',
        type: 'user'
      },
      school: {
        jsonApi: 'hasOne',
        type: 'school'
      }
    }
  },
  {
    name: 'content',
    id: 0,
    attributes: {
      title: '',
      text_content: '',
      created_at: '',
      theme_id: 0,
      segment_id: 0,
      category_id: 0,
      occupation_id: 0,
      school_id: 0,
      audio_url: '',
      video_url: '',
      abstract: '',
      document_urls: [],
      stage: '',
      status: '',
      theme: {
        jsonApi: 'hasOne',
        type: 'themes'
      },
      segment: {
        jsonApi: 'hasOne',
        type: 'segments'
      },
      category: {
        jsonApi: 'hasOne',
        type: 'categories'
      },
      occupation: {
        jsonApi: 'hasOne',
        type: 'categories'
      },
      school: {
        jsonApi: 'hasOne',
        type: 'school'
      },
      views: {
        jsonApi: 'hasMany',
        type: 'views'
      }
    },
    options: {
      collectionPath: 'contents',
      type: 'contents'
    }
  },
  {
    name: 'content_images',
    attributes: {
      name: '',
      school_id: '',
      file: {}
    },
    options: {
      collectionPath: 'content_images',
      type: 'content_images'
    }
  },
  {
    name: 'theme',
    id: 0,
    attributes: {
      title: '',
      description: '',
      contents_order: null,
      theme_module_id: 0,
      cover_id: 0,
      skill_ids: [],
      school_id: 0,
      stages: '',
      segment_ids: [],
      status: '',
      contents: [],
      segments: {
        jsonApi: 'hasMany',
        type: 'segment'
      },
      theme_module: {
        jsonApi: 'hasOne',
        type: 'theme_modules'
      },
      cover: {
        jsonApi: 'hasOne',
        type: 'covers'
      },
      school: {
        jsonApi: 'hasOne',
        type: 'schools'
      },
      skills: {
        jsonApi: 'hasMany',
        type: 'skills'
      }
    },
    options: {
      collectionPath: 'themes',
      type: 'themes'
    }
  },
  {
    name: 'theme_category',
    id: 0,
    attributes: {
      theme_id: 0,
      occupation_id: 0,
      category_ids: [],
      created_at: '',
      updated_at: '',
      theme: {
        jsonApi: 'hasOne',
        type: 'themes'
      },
      occupation: {
        jsonApi: 'hasOne',
        type: 'occupations'
      }
    }
  },
  {
    name: 'child',
    id: '1',
    attributes: {
      name: '',
      birthdate: '',
      avatar: '',
      avatar_url: '',
      user_id: '',
      user: {
        jsonApi: 'hasOne',
        type: 'user'
      },
      taxpayer_number: '',
      classrooms: {
        jsonApi: 'hasMany',
        type: 'classrooms'
      },
      parents: {
        jsonApi: 'hasMany',
        type: 'parents'
      },
      child_parents: {
        jsonApi: 'hasMany',
        type: 'child_parents'
      }
    }
  },
  {
    name: 'classroom',
    id: 0,
    attributes: {
      name: '',
      grade_id: 0,
      school_id: 0,
      year: 0,
      is_main_classroom: false,
      shift: '',
      communications: {
        jsonApi: 'hasMany',
        type: 'communications'
      },
      grade: {
        jsonApi: 'hasOne',
        type: 'grade'
      },
      segment_id: 0,
      segment: {
        jsonApi: 'hasOne',
        type: 'segment'
      },
      school: {
        jsonApi: 'hasOne',
        type: 'school'
      },
      teachers: {
        jsonApi: 'hasMany',
        type: 'teacher'
      },
      children: {
        jsonApi: 'hasMany',
        type: 'children'
      },
      classroom_teachers: {
        jsonApi: 'hasMany',
        type: 'classroom_teachers'
      }
    }
  },
  {
    name: 'classroom_child',
    id: 0,
    attributes: {
      child_id: 0,
      classroom_id: 0,
      child: {
        jsonApi: 'hasOne',
        type: 'child'
      },
      classroom: {
        jsonApi: 'hasOne',
        type: 'classroom'
      }
    }
  },
  {
    name: 'user_occupation',
    id: 0,
    attributes: {
      user_id: 0,
      occupation_id: 0,
      user: {
        jsonApi: 'hasOne',
        type: 'user'
      },
      occupation: {
        jsonApi: 'hasOne',
        type: 'occupation'
      }
    }
  },
  {
    name: 'occupation',
    id: 0,
    attributes: {
      name: ''
    }
  },
  {
    name: 'user_config',
    id: 0,
    attributes: {
      user_id: '',
      notify_by_push: false,
      notify_by_email: false,
      user: {
        jsonApi: 'hasOne',
        type: 'user'
      }
    }
  },
  {
    name: 'user',
    id: 0,
    attributes: {
      email: '',
      fractal_id: 0,
      name: '',
      phone: '',
      child_id: '',
      avatar: '',
      taxpayer_number: '',
      financial_payer: null,
      user_configs: null,
      children: null,
      contact_from_school: null,
      avatar_url: '',
      address: {
        street: '',
        complement: '',
        zipcode: '',
        number: '',
        neighbourhood: ''
      },
      last_access_at: '',
      user_occupations_attributes: [],
      school_administrators_attributes: [],
      school_content_creators_attributes: [],
      classroom_attributes: [],
      child_attributes: [],
      classroom_teachers_attributes: [],
      child_parents_attributes: [],
      teacher_classrooms_attributes: [],
      timesheets_attributes: [],
      school_coordinators_attributes: [],
      classroom_coordinators_attributes: [],
      classrooms_id_by_teachers: null,
      user_config: {
        jsonApi: 'hasOne',
        type: 'user_configs'
      },
      classrooms: {
        jsonApi: 'hasMany',
        type: 'classroom'
      },
      timesheets: {
        jsonApi: 'hasMany',
        type: 'timesheet'
      },
      user_occupations: {
        jsonApi: 'hasMany'
      },
      teacher_classrooms: {
        jsonApi: 'hasMany',
        type: 'classroom'
      },
      apps: {
        jsonApi: 'hasMany'
      },
      child: {
        jsonApi: 'hasOne',
        type: 'child'
      },
      school_administrators: {
        jsonApi: 'hasOne',
        type: 'school_administrators'
      },
      school_content_creators: {
        jsonApi: 'hasOne',
        type: 'school_content_creators'
      },
      classroom_teachers: {
        jsonApi: 'hasMany',
        type: 'classroom_teachers'
      },
      child_parents: {
        jsonApi: 'hasMany',
        type: 'child_parents'
      },
      occupations: {
        jsonApi: 'hasMany',
        type: 'occupation'
      },
      school_moderators: {
        jsonApi: 'hasMany',
        type: 'school_moderator'
      },
      classroom_moderators: {
        jsonApi: 'hasMany',
        type: 'classroom_moderator'
      },
      classroom_coordinators: {
        jsonApi: 'hasMany',
        type: 'classroom_coordinator'
      },
      school_coordinators: {
        jsonApi: 'hasMany',
        type: 'school_coordinator'
      }
    }
  },
  {
    name: 'school_coordinator',
    id: 0,
    attributes: {
      user_id: 0,
      school_id: 0,
      coordinator: {
        jsonApi: 'hasOne',
        type: 'user'
      },
      school: {
        jsonApi: 'hasOne',
        type: 'school'
      }
    }
  },
  {
    name: 'classroom_coordinator',
    id: 0,
    attributes: {
      user_id: 0,
      classroom_id: 0,
      coordinator: {
        jsonApi: 'hasOne',
        type: 'user'
      },
      classroom: {
        jsonApi: 'hasOne',
        type: 'classroom'
      }
    }
  },
  {
    name: 'teacher_subject',
    id: 0,
    attributes: {
      school_teacher_id: 0,
      subject_id: 0,
      created_at: '',
      updated_at: '',
      subject: {
        jsonApi: 'hasOne',
        type: 'subject'
      },
      school_teacher: {
        jsonApi: 'hasOne',
        type: 'school_teacher'
      }
    }
  },
  {
    name: 'teaching_record',
    id: 0,
    attributes: {
      text: '',
      content_id: 0,
      user_id: 0,
      created_at: '',
      updated_at: '',
      content: {
        jsonApi: 'hasOne',
        type: 'contents'
      },
      files: {
        jsonApi: 'hasMany',
        type: 'files'
      },
      destinations: {
        jsonApi: 'hasMany',
        type: 'destinations'
      },
      views: {
        jsonApi: 'hasMany',
        type: 'views'
      }
    }
  },
  {
    name: 'teaching_record_destination',
    id: 0,
    attributes: {
      classroom_id: 0,
      child_id: 0
    }
  },
  {
    name: 'teaching_record_file',
    id: 0,
    attributes: {
      caption: '',
      file_type: '',
      file_url: ''
    }
  },
  {
    name: 'school_config',
    id: 0,
    attributes: {
      moderate_events: '',
      moderate_reports: '',
      moderate_messages: '',
      moderate_utilities: '',
      created_at: '',
      updated_at: '',
      school_id: 0,
      school: {
        jsonApi: 'hasOne',
        type: 'school'
      }
    }
  },
  {
    name: 'school',
    id: 0,
    attributes: {
      name: '',
      avatar: '',
      avatar_url: '',
      avatar_attachment_id: '',
      primary_color: '',
      secondary_color: '',
      logo: '',
      logo_url: '',
      minilogo: '',
      minilogo_url: '',
      instagram_url: '',
      facebook_url: '',
      twitter_url: '',
      youtube_url: '',
      school_config_id: 0,
      school_config: {
        jsonApi: 'hasOne',
        type: 'school_config'
      },
      apps: {
        jsonApi: 'hasMany'
      },
      administrators: {
        jsonApi: 'hasMany',
        type: 'administrator'
      },
      classrooms: {
        jsonApi: 'hasMany',
        type: 'classroom'
      }
    }
  },
  {
    name: 'school_content_creators',
    id: 0,
    attributes: {
      user_id: 0,
      school_id: 0,
      content_creator: {
        jsonApi: 'hasOne',
        type: 'content_creator'
      },
      school: {
        jsonApi: 'hasOne',
        type: 'schoool'
      }
    }
  },
  {
    name: 'school_administrator',
    id: 0,
    attributes: {
      user_id: 0,
      school_id: 0,
      administrator: {
        jsonApi: 'hasOne',
        type: 'administrator'
      },
      school: {
        jsonApi: 'hasOne',
        type: 'school'
      }
    }
  },
  {
    name: 'view',
    id: 0,
    attributes: {
      seen_at: '',
      user: {
        jsonApi: 'hasOne',
        type: 'user'
      }
    }
  },
  {
    name: 'cover',
    id: 0,
    attributes: {
      image_url: '',
      properties: {}
    }
  },
  {
    name: 'segment',
    id: 0,
    attributes: {
      name: '',
      skills: {
        jsonApi: 'hasMany',
        type: 'skills'
      }
    }
  },
  {
    name: 'skill',
    id: 0,
    attributes: {
      name: '',
      segment_ids: [],
      segments: {
        jsonApi: 'hasMany',
        type: 'segments'
      }
    }
  },
  {
    name: 'category',
    id: 0,
    attributes: {
      name: '',
      classrooms: {
        jsonApi: 'hasMany',
        type: 'classroom'
      },
      school: {
        jsonApi: 'hasOne',
        type: 'school'
      }
    }
  },
  {
    name: 'schedule_template',
    id: 0,
    attributes: {
      name: '',
      template: '',
      active: 0,
      grade_id: 0,
      school_id: 0,
      school: {
        jsonApi: 'hasOne',
        type: 'school'
      },
      grade: {
        jsonApi: 'hasOne',
        type: 'grade'
      },
      created_by: {
        jsonApi: 'hasOne',
        type: 'user'
      }
    }
  },
  {
    name: 'theme_schedule',
    id: 0,
    attributes: {
      start_at: '',
      end_at: '',
      schedule_template_id: null,
      school_id: 0,
      grade_id: null,
      theme_id: 0,
      cycle_id: 0,
      created_at: '',
      favorited: false,
      school: {
        jsonApi: 'hasOne',
        type: 'school'
      },
      schedule_template: {
        jsonApi: 'hasOne',
        type: 'schedule_template'
      },
      grade: {
        jsonApi: 'hasOne',
        type: 'grade'
      },
      theme: {
        jsonApi: 'hasOne',
        type: 'theme'
      },
      cycle: {
        jsonApi: 'hasOne',
        type: 'cycle'
      }
    }
  },
  {
    name: 'classroom_teacher',
    id: 0,
    attributes: {
      user_id: 0,
      classroom_id: 0,
      deleted_at: '',
      teacher: {
        jsonApi: 'hasOne',
        type: 'teacher'
      },
      classroom: {
        jsonApi: 'hasOne',
        type: 'classroom'
      }
    }
  },
  {
    name: 'cycle',
    id: 0,
    attributes: {
      name: '',
      school_id: '',
      school: {
        jsonApi: 'hasOne',
        type: 'school'
      },
      theme_schedules: {
        jsonApi: 'hasMany',
        type: 'theme_schedule'
      }
    }
  },
  {
    name: 'child_parent',
    id: 0,
    attributes: {
      user_id: '',
      child_id: '',
      relationship_type: '',
      description: '',
      parent: {
        jsonApi: 'hasOne',
        type: 'parent'
      },
      child: {
        jsonApi: 'hasOne',
        type: 'child'
      }
    }
  },
  {
    name: 'school_teacher',
    id: 0,
    attributes: {
      user_id: 0,
      school_id: 0,
      teacher_subjects_attributes: [],
      teacher: {
        jsonApi: 'hasOne',
        type: 'user'
      },
      school: {
        jsonApi: 'hasOne',
        type: 'school'
      },
      teacher_subjects: {
        jsonApi: 'hasMany',
        type: 'teacher_subject'
      }
    }
  },
  {
    name: 'communication',
    id: 0,
    attributes: {
      school_id: 0,
      classroom_id: 0,
      status: '',
      origin_id: 0,
      active: false,
      message_destinations_attributes: [],
      files_attributes: [],
      created_at: '',
      updated_at: '',
      finished_at: '',
      finished_by_id: '',
      finished_by: {
        jsonApi: 'hasOne',
        type: 'user'
      },
      messages: {
        jsonApi: 'hasMany',
        type: 'messages'
      },
      users: {
        jsonApi: 'hasMany',
        type: 'user'
      },
      school: {
        jsonApi: 'hasOne',
        type: 'school'
      },
      classroom: {
        jsonApi: 'hasOne',
        type: 'classroom'
      },
      origin: {
        jsonApi: 'hasOne',
        type: 'users'
      }
    }
  },
  {
    name: 'message',
    id: 0,
    attributes: {
      moderator_id: 0,
      title: '',
      content: '',
      status: '',
      message_type: '',
      communication_id: 0,
      author_id: 0,
      parent_id: 0,
      created_at: '',
      updated_at: '',
      viewed_by_moderator_at: '',
      moderated_at: '',
      refused_at: '',
      communication: {
        jsonApi: 'hasOne',
        type: 'communication'
      },
      parent: {
        jsonApi: 'hasOne',
        type: 'message'
      },
      moderator: {
        jsonApi: 'hasOne',
        type: 'school_moderator'
      },
      users: {
        jsonApi: 'hasMany',
        type: 'user'
      },
      author: {
        jsonApi: 'hasOne',
        type: 'user'
      },
      children: {
        jsonApi: 'hasMany'
      },
      files: {
        jsonApi: 'hasMany',
        type: 'message_files'
      },
      message_destinations: {
        jsonApi: 'hasMany',
        type: 'message_destination'
      },
      message_views: {
        jsonApi: 'hasMany',
        type: 'message_views'
      }
    }
  },
  {
    name: 'message_destination',
    id: 0,
    attributes: {
      viewed: false,
      message_id: 0,
      user_id: 0,
      communication_id: 0,
      classroom_id: 0,
      classroom_teachers_destination_id: 0,
      classroom_children_destination_id: 0,
      classroom_parents_destination_id: 0,
      updated_at: '',
      user: {
        jsonApi: 'hasOne',
        type: 'user'
      },
      school: {
        jsonApi: 'hasOne',
        type: 'school'
      },
      classroom: {
        jsonApi: 'hasOne',
        type: 'classroom'
      },
      classroom_parents_destination: {
        jsonApi: 'hasOne',
        type: 'user'
      },
      classroom_teachers_destination: {
        jsonApi: 'hasOne',
        type: 'user'
      },
      classroom_children_destination: {
        jsonApi: 'hasOne',
        type: 'user'
      },
      message: {
        jsonApi: 'hasOne',
        type: 'message'
      },
      communication: {
        jsonApi: 'hasOne',
        type: 'communication'
      }
    }
  },
  {
    name: 'message_file',
    id: 0,
    attributes: {
      content_type: '',
      size: '',
      filename: '',
      file_url: '',
      message_id: '',
      file: {},
      message: {
        jsonApi: 'hasOne',
        type: 'message'
      }
    }
  },
  {
    name: 'message_view',
    id: 0,
    attributes: {
      message_id: 0,
      user_id: 0,
      created_at: '',
      message: {
        jsonApi: 'hasOne',
        type: 'message'
      },
      user: {
        jsonApi: 'hasOne',
        type: 'user'
      }
    }
  },
  {
    name: 'clone_theme',
    attributes: {
      theme_id: 0,
      school_id: 0
    },
    options: {
      collectionPath: 'themes/clone_theme',
      type: 'themes'
    }
  },
  {
    name: 'event',
    id: 0,
    attributes: {
      title: '',
      description: '',
      event_type: '',
      moderated_at: '',
      mod_note: '',
      refused_at: '',
      scheduled_at: '',
      absences: [],
      moderator_id: 0,
      user_id: 0,
      status: 0,
      school_id: 0,
      grade_id: 0,
      classroom_id: 0,
      event_date: '',
      created_at: '',
      start_time: '',
      end_time: '',
      confirmation_date: '',
      participants: [],
      cover_image_url: '',
      event_destination: 0,
      moderator: {
        jsonApi: 'hasOne',
        type: 'user'
      },
      user: {
        jsonApi: 'hasOne',
        type: 'user'
      },
      school: {
        jsonApi: 'hasOne',
        type: 'school'
      },
      grade: {
        jsonApi: 'hasOne',
        type: 'grade'
      },
      classroom: {
        jsonApi: 'hasOne',
        type: 'classroom'
      },
      files: {
        jsonApi: 'hasMany',
        type: 'event_file'
      },
      destinations: {
        jsonApi: 'hasMany',
        type: 'event_destination'
      },
      event_comments: {
        jsonApi: 'hasMany',
        type: 'event_comment'
      },
      event_likes: {
        jsonApi: 'hasMany',
        type: 'event_like'
      },
      event_views: {
        jsonApi: 'hasMany',
        type: 'event_view'
      }
    }
  },
  {
    name: 'event_destination',
    id: 0,
    attributes: {
      event_id: 0,
      user_id: 0,
      classroom_id: 0,
      grade_id: 0,
      school_id: 0,
      destination_type: '',
      event: {
        jsonApi: 'hasOne',
        type: 'event'
      },
      user: {
        jsonApi: 'hasOne',
        type: 'user'
      },
      grade: {
        jsonApi: 'hasOne',
        type: 'grade'
      },
      school: {
        jsonApi: 'hasOne',
        type: 'school'
      },
      classroom: {
        jsonApi: 'hasOne',
        type: 'classroom'
      }
    }
  },
  {
    name: 'event_like',
    id: 0,
    attributes: {
      event_id: 0,
      user_id: 0,
      liked_at: '',
      user_role: '',
      event: {
        jsonApi: 'hasOne',
        type: 'event'
      },
      user: {
        jsonApi: 'hasOne',
        type: 'user'
      }
    }
  },
  {
    name: 'event_view',
    id: 0,
    attributes: {
      event_id: 0,
      viewer_role: '',
      viewing_platform: '',
      user_id: 0,
      created_at: '',
      event: {
        jsonApi: 'hasOne',
        type: 'event'
      },
      user: {
        jsonApi: 'hasOne',
        type: 'user'
      }
    }
  },
  {
    name: 'event_comment',
    id: 0,
    attributes: {
      content: '',
      created_at: '',
      event_id: 0,
      user_id: 0,
      event: {
        jsonApi: 'hasOne',
        type: 'event'
      },
      user: {
        jsonApi: 'hasOne',
        type: 'user'
      }
    }
  },
  {
    name: 'event_file',
    id: 0,
    attributes: {
      content_type: '',
      size: '',
      filename: '',
      file_url: '',
      note: '',
      event_id: 0,
      file: '',
      event: {
        jsonApi: 'hasOne',
        type: 'event'
      }
    }
  },
  {
    name: 'report',
    id: 0,
    attributes: {
      report_template_id: 0,
      user_id: 0,
      status: '',
      finished_at: '',
      created_at: '',
      updated_at: '',
      report_template: {
        jsonApi: 'hasOne',
        type: 'report_template'
      },
      user: {
        jsonApi: 'hasOne',
        type: 'user'
      },
      report_students: {
        jsonApi: 'hasMany',
        type: 'report_student'
      },
      questions: {
        jsonApi: 'hasMany',
        type: 'report_question'
      }
    }
  },
  {
    name: 'report_template',
    id: 0,
    attributes: {
      title: '',
      status: 0,
      students_count: 0,
      user_id: 0,
      classroom_id: 0,
      year: '',
      created_at: '',
      updated_at: '',
      finished_at: '',
      questions_attributes: '',
      tags: {
        jsonApi: 'hasMany',
        type: 'tag'
      },
      questions: {
        jsonApi: 'hasMany',
        type: 'report_question'
      },
      reports: {
        jsonApi: 'hasMany',
        type: 'report'
      },
      user: {
        jsonApi: 'hasOne',
        type: 'user'
      },
      classroom: {
        jsonApi: 'hasMany',
        type: 'classroom'
      }
    }
  },
  {
    name: 'report_student_view',
    id: 0,
    attributes: {
      report_student_id: 0,
      user_id: 0,
      viewer_role: '',
      created_at: '',
      updated_at: '',
      report_student: {
        jsonApi: 'hasOne',
        type: 'report_student'
      },
      user: {
        jsonApi: 'hasOne',
        type: 'user'
      }
    }
  },
  {
    name: 'report_student_file',
    id: 0,
    attributes: {
      report_file_id: 0,
      report_file: {
        jsonApi: 'hasOne',
        type: 'report_file'
      },
      report_student_id: 0,
      report_student: {
        jsonApi: 'hasOne',
        type: 'report_student'
      }
    }
  },
  {
    name: 'report_file',
    id: 0,
    attributes: {
      content_type: '',
      size: '',
      filename: '',
      file_url: '',
      tag_id: 0,
      file: ''
    }
  },
  {
    name: 'report_student',
    id: 0,
    attributes: {
      user_id: 0,
      report_id: 0,
      frequency: '',
      mod_note: '',
      moderator_id: 0,
      moderated_at: '',
      refused_at: '',
      child_id: 0,
      status: '',
      finished_at: '',
      created_at: '',
      absent_message: '',
      updated_at: '',
      report_student_type: '',
      report_student_answers_attributes: '',
      report_student_files_attributes: '',
      report_student_answers_id: 0,
      report_files: {
        jsonApi: 'hasMany',
        type: 'report_files'
      },
      views: {
        jsonApi: 'hasMany',
        type: 'report_student_view'
      },
      moderator: {
        jsonApi: 'hasOne',
        type: 'user'
      },
      report_student_answers: {
        jsonApi: 'hasMany',
        type: 'report_student_answer'
      },
      child: {
        jsonApi: 'hasOne',
        type: 'child'
      },
      report: {
        jsonApi: 'hasOne',
        type: 'report'
      },
      user: {
        jsonApi: 'hasOne',
        type: 'user'
      }
    }
  },
  {
    name: 'subject',
    id: 0,
    attributes: {
      name: '',
      tag_id: 0,
      created_at: '',
      updated_at: '',
      tags: {
        jsonApi: 'hasMany',
        type: 'tag'
      },
      subject_order: ''
    }
  },
  {
    name: 'tag',
    id: 0,
    attributes: {
      name: '',
      school_id: 0,
      create_at: '',
      updated_at: '',
      tag_options_id: 0,
      tag_order: '',
      show_who_answered: '',
      description: '',
      tag_type: '',
      school_ids: [],
      subjects: {
        jsonApi: 'hasMany',
        type: 'subject'
      },
      tag_options: {
        jsonApi: 'hasMany',
        type: 'tag_option'
      },
      school: {
        jsonApi: 'hasOne',
        type: 'school'
      }
    }
  },
  {
    name: 'tag_option',
    id: 0,
    attributes: {
      topic: '',
      tag_option_order: '',
      tag_options_type: '',
      default_answer_index: '',
      possible_answers: '',
      colors: '',
      tag_id: 0,
      tag: {
        jsonApi: 'hasOne',
        type: 'tag'
      }
    }
  },
  {
    name: 'report_question',
    id: 0,
    attributes: {
      tag_id: '',
      report_template_id: 0,
      required: false,
      question_order: null,
      created_at: '',
      updated_at: '',
      tag_option_ids: [],
      tag: {
        jsonApi: 'hasOne',
        type: 'tag'
      },
      report_template: {
        jsonApi: 'hasOne',
        type: 'report_template'
      }
    }
  },
  {
    name: 'report_student_answer',
    id: 0,
    attributes: {
      tag_id: 0,
      report_student_id: 0,
      topic: '',
      answer: '',
      created_at: '',
      due_date: '',
      updated_at: '',
      multiple_answers: [],
      user_id: 0,
      subject_id: 0,
      status: '',
      current_answer: null,
      user: {
        jsonApi: 'hasOne',
        type: 'user'
      },
      subject: {
        jsonApi: 'hasOne',
        type: 'subject'
      },
      tag: {
        jsonApi: 'hasOne',
        type: 'tag'
      },
      report_student: {
        jsonApi: 'hasOne',
        type: 'report_student'
      }
    }
  },
  {
    name: 'events_participant',
    attributes: {
      event_id: ''
    },
    options: {
      collectionPath: 'events/participants',
      type: 'events'
    }
  },
  {
    name: 'utility',
    id: 0,
    attributes: {
      school_id: 0,
      grade_id: 0,
      classroom_id: 0,
      title: '',
      utility_type: '',
      start_date: '',
      final_date: '',
      created_at: '',
      updated_at: '',
      file: '',
      scheduled_at: '',
      wrapper_ids: [],
      wrapper_id: 0,
      files: {
        jsonApi: 'hasMany',
        type: 'utility_file'
      },
      school: {
        jsonApi: 'hasOne',
        type: 'school'
      },
      filename: '',
      grade: {
        jsonApi: 'hasMany',
        type: 'grade'
      },
      classroom: {
        jsonApi: 'hasMany',
        type: 'classroom'
      },
      size: '',
      file_url: '',
      content_type: '',
      note: '',
      destinations: {
        jsonApi: 'hasMany',
        type: 'utility_destination'
      }
    }
  },
  {
    name: 'utility_file',
    id: 0,
    attributes: {
      content_type: '',
      size: '',
      filename: '',
      file_url: '',
      utility_id: 0,
      file: '',
      utility: {
        jsonApi: 'hasOne',
        type: 'utility'
      },
      note: ''
    }
  },
  {
    name: 'utility_destination',
    id: 0,
    attributes: {
      utility_id: 0,
      school_id: 0,
      grade_id: 0,
      classroom_id: 0,
      user_id: 0,
      child_id: 0,
      user_ids: [],
      destination_type: '',
      created_at: '',
      updated_at: '',
      child: {
        jsonApi: 'hasOne',
        type: 'child'
      },
      user: {
        jsonApi: 'hasOne',
        type: 'user'
      },
      utility: {
        jsonApi: 'hasOne',
        type: 'utility'
      },
      school: {
        jsonApi: 'hasOne',
        type: 'school'
      },
      grade: {
        jsonApi: 'hasOne',
        type: 'grade'
      },
      classroom: {
        jsonApi: 'hasOne',
        type: 'classroom'
      }
    }
  },
  {
    name: 'material',
    id: 0,
    attributes: {
      title: '',
      description: '',
      status: '',
      material_type: '',
      scheduled_at: '',
      created_at: '',
      updated_at: '',
      user_id: 0,
      subject_id: 0,
      grade_id: 0,
      school_id: 0,
      classroom_id: 0,
      files: {
        jsonApi: 'hasMany',
        type: 'material_file'
      },
      classroom: {
        jsonApi: 'hasOne',
        type: 'classroom'
      },
      grade: {
        jsonApi: 'hasOne',
        type: 'grade'
      },
      school: {
        jsonApi: 'hasOne',
        type: 'school'
      },
      subject: {
        jsonApi: 'hasOne',
        type: 'subject'
      },
      user: {
        jsonApi: 'hasOne',
        type: 'user'
      },
      destinations: {
        jsonApi: 'hasMany',
        type: 'material_destination'
      },
      views: {
        jsonApi: 'hasMany'
      }
    }
  },
  {
    name: 'material_destination',
    id: 0,
    attributes: {
      material_id: 0,
      classroom_id: 0,
      child_id: 0,
      created_at: '',
      updated_at: '',
      classroom: {
        jsonApi: 'hasOne',
        type: 'classroom'
      },
      material: {
        jsonApi: 'hasOne',
        type: 'material'
      },
      child: {
        jsonApi: 'hasOne',
        type: 'child'
      }
    }
  },
  {
    name: 'material_file',
    id: 0,
    attributes: {
      content_type: '',
      size: '',
      filename: '',
      file_url: '',
      material_id: 0,
      link_url: '',
      created_at: '',
      updated_at: '',
      material: {
        jsonApi: 'hasOne',
        type: 'material'
      }
    }
  },
  {
    name: 'material_view',
    id: 0,
    attributes: {
      viewing_platform: '',
      viewer_role: '',
      created_at: '',
      material_id: 0,
      user_id: 0,
      material: {
        jsonApi: 'hasOne',
        type: 'material'
      },
      user: {
        jsonApi: 'hasOne',
        type: 'user'
      }
    }
  }
]
