import React from 'react'
import { connect } from 'react-redux'
import { makeStyles, Tooltip, LinearProgress } from '@material-ui/core'

import CheckboxInput from '../input/CheckboxInput'
import Button from '../button/Button'
import FormProgress from '../shared/FormProgress'
import themePuzzleSVG from '../../assets/icons/theme-puzzle.svg'
import createdContentSVG from '../../assets/icons/content-created-icon.svg'
import sketchedContentSVG from '../../assets/icons/content-sketch-icon.svg'
import StyledSVG from '../shared/StyledSVG'
import editIntoModalSVG from '../../assets/icons/edit-pencil.svg'
import SkillsComponent from '../shared/SkillsComponent'
import focusSVG from '../../assets/icons/content-focus.svg'
import deleteSVG from '../../assets/icons/pattern-delete-icon.svg'
import deleteCrossSVG from '../../assets/icons/pattern-delete-cross-icon.svg'
import closeSideBarArrow from '../../assets/icons/arrowlfts.svg'
import { getOccupationsRSPA } from '../../store/occupations/actions'
import {
  getThemeCategoryRSPA,
  deleteCategoryFromThemeCategoryRSPA,
  deleteThemeCategoryRSPA
} from '../../store/themes/actions'
import ConfirmModal from '../modal/ConfirmModal'

import { occupationsObject } from '../../utils/dicts'
import { fetchCategoriesByIds } from '../../store/categories/services'

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 16,
    '& .title': {
      display: 'flex',
      alignItems: 'center',
      columnGap: 12
    },
    '& path': {
      stroke: '#4D5E80'
    },
    '& h1': {
      fontWeight: 700,
      color: '#4D5E80',
      fontSize: 32,
      margin: 0
    },
    '& span': {
      color: '#555555',
      fontSize: 16,
      maxWidth: '25%'
    }
  },
  steps: {
    marginTop: 40,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 8,
    '& p': {
      margin: 0,
      fontWeight: 700,
      fontSize: 16
    },
    '& span': {
      fontWeight: 500,
      fontSize: 14
    }
  },
  bottomBtns: {
    marginTop: 50,
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 10,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column-reverse',
      alignItems: 'center',
      '& button': {
        width: '200px !important'
      }
    },
    '& button': {
      width: 150
    }
  },
  cancelBtn: {
    width: 146,
    border: '2px solid #EF7C8F',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#EF7C8F',
    borderRadius: 8,
    '&:hover': {
      border: '2px solid #EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  sendBtn: {
    backgroundColor: '#386093',
    width: 148,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  horizontalBar: {
    border: '1px solid rgba(173, 184, 204, 0.3)'
  },
  themeCover: {
    '& img': {
      width: 400,
      height: 230
    }
  },
  projectInfos: {
    margin: '24px 0px',
    display: 'flex'
  },
  projectDetails: {
    marginLeft: 18,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    '& span.label': {
      margin: '0px 0px 8px 0px',
      color: '#ADB8CC',
      fontSize: 14,
      fontWeight: 500
    },
    '& p': {
      margin: 0,
      color: '#555555',
      fontWeight: 700,
      fontSize: 18
    }
  },
  focus: {
    marginBottom: 20,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: 15,
    '& .total': {
      '& span': {
        fontSize: 12,
        fontWeight: 600,
        '& strong': {
          fontWeight: 700
        }
      }
    }
  },
  focus__label: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    '& h1': {
      color: '#555555',
      fontSize: 24,
      fontWeight: 700
    }
  },
  focus__delete: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 2,
    backgroundColor: '#EF7C8F1A',
    '& path': {
      stroke: '#EF7C8F'
    },
    position: 'absolute',
    right: 0,
    bottom: 0,
    cursor: 'pointer'
  },
  focusByOccupation: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    marginTop: 18
  },
  avatar: {
    borderRadius: 16,
    width: 35,
    height: 35,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& span': {
      fontWeight: 700
    }
  },
  avatar__parent: {
    backgroundColor: '#8B598E33',
    '& span': {
      color: '#8B598E'
    }
  },
  avatar__teacher: {
    backgroundColor: '#F4A87C33',
    '& span': {
      color: '#F4A87C'
    }
  },
  avatar__student: {
    backgroundColor: '#636AAF1A',
    '& span': {
      color: '#636AAF'
    }
  },
  occupationSection: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    '& .MuiButtonBase-root': {
      padding: 0
    },
    '& .label': {
      color: '#555555',
      fontSize: 16,
      fontWeight: 600
    }
  },
  lineOne: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  categoriesOverview: {
    display: 'flex',
    width: '20%',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& span': {
      fontWeight: 500,
      fontSize: 16,
      color: '#ADB8CC'
    }
  },
  lineTwo: {
    overflow: 'hidden',
    margin: '24px 0px',
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    transition: 'height 0.5s ease-in-out',
    '&::-webkit-scrollbar': {
      width: 0
    }
  },
  category: {
    minHeight: 70,
    padding: '8px 24px 8px 70px',
    borderRadius: 8,
    backgroundColor: '#CED6E01A',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  category__titles: {
    '& .title': {
      width: '15%',
      margin: 0,
      fontWeight: 600,
      fontSize: 16,
      color: '#666666'
    },
    '& .subtitle': {
      color: '#ADB8CC',
      margin: 0,
      fontSize: 12,
      fontWeight: 500
    }
  },
  category__details: {
    display: 'flex',
    gap: 200
  },
  category__details__flags: {
    display: 'flex',
    alignItems: 'center',
    gap: 100,
    '& span': {
      maxWidth: '66px',
      width: '66px',
      color: '#ADB8CC',
      fontSize: 16
    }
  },
  category__details__actions: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
    '& svg': {
      cursor: 'pointer'
    }
  },
  linearProgress: {
    backgroundColor: '#CED6E04D',
    borderRadius: '20px',
    '& .MuiLinearProgress-bar': {
      backgroundColor: '#F4A87C'
    }
  }
}))

const FocusComponent = props => {
  const {
    focus,
    history,
    currentTheme,
    setFocus,
    deleteCategoryFromThemeCategoryRSPA,
    focusCheck,
    setFocusCheck
  } = props

  const [modalToDelete, setModalToDelete] = React.useState({
    open: false,
    category: null,
    themeCategory: null
  })

  const classes = useStyles()

  const [arrowDown, setArrowDown] = React.useState(
    focus.map(item => {
      return { id: item.id, open: false }
    })
  )

  const handleRemoveCategory = async (categoryId, themeCategoryId) => {
    await deleteCategoryFromThemeCategoryRSPA({ categoryId, themeCategoryId })
    setFocus(
      focus.map(item => {
        if (item.id === themeCategoryId) {
          return {
            ...item,
            categories: item.categories.filter(
              category => category.id !== categoryId
            )
          }
        }
        return item
      })
    )
  }
  const handleFocusCheck = event => {
    setFocusCheck(
      focusCheck.map(item => {
        if (item.id === event.target.name.split('_')[1]) {
          return {
            ...item,
            checked: !item.checked,
            title: event.target.name.split('_')[0]
          }
        }
        return item
      })
    )
  }

  return (
    <div className={classes.focusByOccupation}>
      {focus.map((item, index) => {
        const categoriesProgress = currentTheme?.contents.reduce(
          (acc, content) => {
            if (
              content.occupation_id === item.occupation_id &&
              (item.category_ids.some(
                category => content.category_id === category
              ) &&
                content.status !== 'sketched')
            ) {
              return acc + 1
            } else {
              return acc
            }
          },
          0
        )
        return (
          <div key={index} className='root'>
            <div className={classes.lineOne}>
              <div className={classes.occupationSection}>
                <CheckboxInput
                  checked={focusCheck?.find(obj => obj.id === item.id)?.checked}
                  name={`${occupationsObject[item.occupation.name]}_${
                    item?.id
                  }`}
                  handleCheck={handleFocusCheck}
                />
                <div
                  className={`${classes.avatar} ${
                    item.occupation_id === 8
                      ? classes.avatar__teacher
                      : item.occupation_id === 10
                        ? classes.avatar__parent
                        : classes.avatar__student
                  }`}
                >
                  <span>
                    {occupationsObject[item.occupation.name][0].toUpperCase()}
                  </span>
                </div>
                <span className='label'>
                  {occupationsObject[item.occupation.name]}
                </span>
              </div>
              <div className={classes.categoriesOverview}>
                <div>
                  <span>
                    {' '}
                    Categorias concluídas: {categoriesProgress}/{
                      item.categories.length
                    }
                  </span>
                  <LinearProgress
                    className={classes.linearProgress}
                    variant='determinate'
                    value={+categoriesProgress / +item.categories.length * 100}
                  />
                </div>
                <img
                  src={closeSideBarArrow}
                  alt='closeSideBarArrow'
                  onClick={() => {
                    setArrowDown(
                      arrowDown.map(arrow => {
                        if (arrow.id === item.id) {
                          return { ...arrow, open: !arrow.open }
                        }
                        return arrow
                      })
                    )
                  }}
                  style={{
                    cursor: 'pointer',
                    width: 12,
                    height: 12,
                    transform: !arrowDown[index]?.open
                      ? 'rotate(90deg)'
                      : 'rotate(270deg)',
                    transition: 'all 0.2s ease-in-out'
                  }}
                />
              </div>
            </div>
            <div
              className={classes.lineTwo}
              style={
                !arrowDown[index]?.open
                  ? { height: '350px', overflow: 'auto' }
                  : { height: '0px' }
              }
            >
              {item.categories.map((category, index) => {
                const contentAlreadyRegistered = currentTheme?.contents?.find(
                  content => {
                    return (
                      +content.occupation_id === +item.occupation_id &&
                      +content.category_id === +category.id
                    )
                  }
                )
                const isSketch = contentAlreadyRegistered?.status === 'sketched'
                return (
                  <div className={classes.category} key={index}>
                    <div className={classes.category__titles}>
                      <h1 className='title'>{category.attributes.name}</h1>
                      <span className='subtitle'>
                        {contentAlreadyRegistered?.title}
                      </span>
                    </div>

                    <div className={classes.category__details}>
                      {contentAlreadyRegistered && (
                        <div className={classes.category__details__flags}>
                          <Tooltip
                            placement='right-start'
                            title={isSketch ? 'Categoria em rascunho' : ''}
                          >
                            <div>
                              <StyledSVG
                                src={
                                  isSketch
                                    ? sketchedContentSVG
                                    : createdContentSVG
                                }
                                height={75}
                                width={60}
                              />
                            </div>
                          </Tooltip>
                          <span>
                            {!isSketch
                              ? `Etapa: ${contentAlreadyRegistered.stage}`
                              : ''}
                          </span>
                        </div>
                      )}
                      <div className={classes.category__details__actions}>
                        <Tooltip
                          title={
                            isSketch
                              ? 'Editar rascunho'
                              : contentAlreadyRegistered
                                ? 'Editar conteúdo'
                                : 'Criar conteúdo'
                          }
                        >
                          <div
                            onClick={() => {
                              if (contentAlreadyRegistered) {
                                history.push(
                                  `/newcontents/${currentTheme.id}/edit/${
                                    contentAlreadyRegistered.id
                                  }`
                                )
                              } else {
                                history.push(
                                  `/newcontents/${currentTheme.id}/create`,
                                  {
                                    category: {
                                      label: category.attributes.name,
                                      value: category.id
                                    },
                                    occupation: {
                                      label:
                                        occupationsObject[item.occupation.name],
                                      value: item.occupation_id
                                    }
                                  }
                                )
                              }
                            }}
                          >
                            <StyledSVG
                              src={editIntoModalSVG}
                              height={16}
                              width={16}
                            />
                          </div>
                        </Tooltip>
                        <Tooltip title='Remover categoria'>
                          <div
                            onClick={() =>
                              setModalToDelete({
                                open: true,
                                category,
                                themeCategory: item
                              })
                            }
                          >
                            <StyledSVG
                              src={deleteCrossSVG}
                              height={16}
                              width={16}
                            />
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )
      })}
      <ConfirmModal
        title={`Deseja remover a categoria "${
          modalToDelete?.category?.attributes?.name
        }" do foco 
          de ${
            occupationsObject[modalToDelete?.themeCategory?.occupation?.name]
          } ?`}
        open={modalToDelete.open}
        setOpen={value => {
          if (value) {
            setModalToDelete({ ...modalToDelete, open: value })
          } else {
            setModalToDelete({
              category: null,
              themeCategory: null,
              open: value
            })
          }
        }}
        onConfirm={() =>
          handleRemoveCategory(
            modalToDelete.category.id,
            modalToDelete.themeCategory.id
          )
        }
      />
    </div>
  )
}

const SecondStepThemeForm = props => {
  const {
    currentStep,
    steps,
    change,
    history,
    currentTheme,
    getThemeCategoryRSPA,
    deleteCategoryFromThemeCategoryRSPA,
    deleteThemeCategoryRSPA
  } = props

  const classes = useStyles()
  const [focus, setFocus] = React.useState([])
  const [hasFocusToDelete, setHasFocusToDelete] = React.useState(false)
  const [deleteFocusModal, setDeleteFocusModal] = React.useState({
    open: false,
    title: ''
  })
  const [focusCheck, setFocusCheck] = React.useState(
    focus?.map(item => {
      return { id: item.id, title: '', checked: false }
    })
  )

  const handleDeleteFocus = async () => {
    deleteThemeCategoryRSPA({
      themeCategoryIds: focusCheck
        .filter(item => item.checked)
        .map(item => item.id)
    }).then(res => {
      setFocus(
        focus
          .filter(
            item => !focusCheck.find(check => check.id === item.id).checked
          )
          .map(item => {
            return { ...item, checked: false }
          })
      )
    })
  }

  React.useEffect(
    () => {
      setFocusCheck(prev => {
        return focus?.map(item => {
          return { id: item.id, checked: false }
        })
      })
    },
    [focus]
  )

  React.useEffect(
    () => {
      if (focusCheck.filter(item => item.checked).length > 0) {
        setHasFocusToDelete(true)
      } else {
        setHasFocusToDelete(false)
      }
    },
    [focusCheck]
  )

  React.useEffect(
    () => {
      if (currentTheme?.id) {
        getThemeCategoryRSPA({ themeId: currentTheme.id }).then(async res => {
          const themeCategoriesWithEachCategory = await Promise.all(
            res.map(async item => {
              await fetchCategoriesByIds({ ids: item.category_ids }).then(res =>
                Object.assign(item, { categories: res.data })
              )
              return item
            })
          )
          setFocus(themeCategoriesWithEachCategory)
        })
      }
    },
    [currentTheme]
  )

  return (
    <div>
      <div className={classes.header}>
        <div className='title'>
          <StyledSVG src={themePuzzleSVG} height={32} width={32} />
          <h1>Alimentar foco e criar conteúdo</h1>
        </div>
        <span>Chegamos na etapa de adicionar conteúdo ao seu tema.</span>
      </div>

      <div className={classes.steps}>
        <p>Etapas</p>
        <span>Fique atento as etapas a seguir:</span>
        <FormProgress
          currentStep={currentStep}
          showTooltip={currentTheme?.status !== 'done'}
          tooltipIndex={2}
          tooltipMessage={
            'Preencha todos os focos desse segmento para avançar.'
          }
          steps={steps}
        />
      </div>

      <div className={classes.horizontalBar} />

      <div className={classes.projectInfos}>
        <div className={classes.themeCover}>
          <img src={currentTheme?.cover?.image_url} />
        </div>
        <div className={classes.projectDetails}>
          <div>
            <span className='label'>Módulo</span>
            <p>{currentTheme?.theme_module?.title}</p>
          </div>
          <div>
            <span className='label'>Tema</span>
            <p>{currentTheme?.title}</p>
          </div>
          <div>
            <span className='label'>Série</span>
            <p>{currentTheme?.theme_module?.grade?.name}</p>
          </div>
          <div>
            <span className='label'>Habilidades</span>
            <SkillsComponent skills={currentTheme?.skills} />
          </div>
        </div>
      </div>

      <div className={classes.horizontalBar} />

      <div className={classes.focus}>
        <div className={classes.focus__label}>
          <StyledSVG src={focusSVG} height={32} width={32} />
          <h1>Focos</h1>
        </div>
        <span className='total'>
          <strong>Total: </strong> {focus.length} focos
        </span>
        <Tooltip
          title={
            focus?.length === focusCheck.filter(objc => objc.checked).length ||
            (focus.length < 2 && hasFocusToDelete)
              ? 'O tema precisa de pelo menos um foco'
              : hasFocusToDelete
                ? 'Deletar focos selecionados'
                : 'Selecione os focos para deletar'
          }
        >
          <div
            onClick={() =>
              focus?.length !==
                focusCheck.filter(objc => objc.checked)?.length &&
              (hasFocusToDelete && focus.length > 1) &&
              setDeleteFocusModal({
                open: true,
                title: focusCheck.reduce((acc, current) => {
                  if (current.checked) {
                    return `${acc} ${current.title}`
                  }
                  return acc
                }, '')
              })
            }
            className={classes.focus__delete}
          >
            <StyledSVG src={deleteSVG} height={28} width={28} />
          </div>
        </Tooltip>
      </div>

      <div className={classes.horizontalBar} />

      {focus.length > 0 && (
        <FocusComponent
          history={history}
          currentTheme={currentTheme}
          focusCheck={focusCheck}
          setFocusCheck={setFocusCheck}
          focus={focus}
          setFocus={setFocus}
          deleteCategoryFromThemeCategoryRSPA={
            deleteCategoryFromThemeCategoryRSPA
          }
        />
      )}
      <div className={classes.bottomBtns}>
        <Button
          type='button'
          variant={'outlined'}
          className={classes.cancelBtn}
          onClick={() =>
            currentTheme?.id &&
            history.push(`/newthemes/${currentTheme?.id}/edit`)
          }
        >
          Voltar
        </Button>
        <Button
          type='submit'
          onClick={() => change('currentStep', currentStep)}
          className={classes.sendBtn}
          disabled={currentTheme?.status !== 'done'}
        >
          Seguir
        </Button>
      </div>
      <ConfirmModal
        title={`Deseja excluir o(s) foco(s): ${deleteFocusModal.title} ?`}
        open={deleteFocusModal.open}
        setOpen={value => {
          if (value) {
            setDeleteFocusModal({ ...deleteFocusModal, open: value })
          } else {
            setDeleteFocusModal({
              title: '',
              open: value
            })
          }
        }}
        onConfirm={() => handleDeleteFocus()}
      />
    </div>
  )
}

const mapStateToProps = (state, props) => {
  return {
    // initialValues: props.initialValues
  }
}

const mapDispatchToProps = dispatch => ({
  getOccupationsRSPA: data => dispatch(getOccupationsRSPA.request(data)),
  getThemeCategoryRSPA: data => dispatch(getThemeCategoryRSPA.request(data)),
  deleteCategoryFromThemeCategoryRSPA: data =>
    dispatch(deleteCategoryFromThemeCategoryRSPA.request(data)),
  deleteThemeCategoryRSPA: data =>
    dispatch(deleteThemeCategoryRSPA.request(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SecondStepThemeForm)
