import { all, call, takeLatest, put } from 'redux-saga/effects'
import {
  rejectPromiseAction,
  resolvePromiseAction
} from 'redux-saga-promise-actions'

import * as actions from './actions'
import * as services from './services'

export function * fetchOccupations ({ payload }) {
  try {
    const response = yield call(services.fetchOccupationsBySegmentId, payload)
    yield put(actions.getOccupationsSuccessful(response.data))
  } catch (error) {
    yield put(actions.getOccupationsFailure(error))
  }
}

export function * fetchOccupationsRSPA (action) {
  try {
    const { payload } = action
    const response = yield call(services.fetchOccupationsBySegmentId, payload)
    resolvePromiseAction(action, response)
  } catch (error) {
    console.error(error)
    rejectPromiseAction(action, error)
  }
}

export function * watchFetchOccupations () {
  yield takeLatest(actions.getOccupations, fetchOccupations)
  yield takeLatest(actions.getOccupationsRSPA.request, fetchOccupationsRSPA)
}

export default function * occupationsSagas () {
  yield all([watchFetchOccupations()])
}
